import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";

const GoogleLoginButton = () => {
    const clientId = "728220278251-paf26nj043vmakq3sk39tgtmrlhhjhrd.apps.googleusercontent.com";

    const handleSuccess = async (credentialResponse) => {
        const { credential } = credentialResponse;

        try {
            const userInfo = jwtDecode(credential); // Decodificar token JWT

            // Enviar datos al backend
            const response = await axios.post("https://apiguatemala.toppaylatam.com/ultra/users/register-google", {
                email: userInfo.email,
                name: userInfo.name,
                picture: userInfo.picture,
            });

            // Manejo de la respuesta del backend
            if (response.status === 201) {
                Swal.fire({
                    title: "¡Bienvenido!",
                    text: "Usuario registrado exitosamente con Google.",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
            }
        } catch (err) {
            if (err.response && err.response.status === 409) {
                // Usuario ya registrado
                Swal.fire({
                    title: "Ya estás registrado",
                    text: "Parece que ya te has registrado anteriormente. ¡Gracias!",
                    icon: "info",
                    confirmButtonText: "Aceptar",
                });
            } else {
                // Otro tipo de error
                Swal.fire({
                    title: "Error",
                    text: "Ocurrió un error al registrar al usuario. Inténtalo nuevamente.",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
        }
    };


    const handleFailure = (error) => {
        Swal.fire({
            title: "Error",
            text: "No se pudo iniciar sesión con Google.",
            icon: "error",
            confirmButtonText: "Aceptar",
        });
    };

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <div>
                {/* <h2>O regístrate con Google</h2> */}
                <GoogleLogin onSuccess={handleSuccess} onError={handleFailure} text="signup_with" logo_alignment="center" size="large" shape="pill" theme="filled_black" />
            </div>
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginButton;
