import React from "react";
import RegistrationForm from "./components/RegistrationForm/RegistrationForm";
// import GoogleLoginButton from "./components/GoogleLoginButton/GoogleLoginButton";
import AdminView from "./components/AdminView/AdminView";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Asegúrate de importar BrowserRouter

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          {/* Ruta para la vista de usuarios */}
          <Route path="/user" element={<AdminView />} />
          {/* Otras rutas, si las tienes */}
          <Route path="*" element={<RegistrationForm />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
